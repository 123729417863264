import React from 'react';
import { createContext } from 'react';
import { useState } from 'react';
import { useContext } from 'react';

const InitialValue = [
  {
    name: 'Fill your details',
    id: 1,
    status: true,
    completed: false,
  },
  {
    name: 'Payment details',
    id: 2,
    status: false,
    completed: false,
  },
  {
    name: 'Verify details',
    id: 3,
    status: false,
    completed: false,
  },
  {
    name: 'Payment status',
    id: 4,
    status: false,
    completed: false,
  },
];

const ProgressContext = createContext();

export const useProgressContext = () => useContext(ProgressContext);
const ProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState(InitialValue);

  const setActiveState = (stateId) => {
    setProgress((prevProgress) =>
      prevProgress.map((step, index) => ({
        ...step,
        status: step.id === stateId,
        completed: index < stateId - 1,
      }))
    );
  };

   const getActiveState = () => {
     return progress.find((step) => step.status);
   };

  return (
    <ProgressContext.Provider
      value={{ progress, setActiveState, getActiveState }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export default ProgressProvider;
