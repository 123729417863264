import React, { useEffect, useRef, useState } from 'react';
import { useProgressContext } from '../../ContextProvider/ProgressContext';
import uojApi from '../../Common/Api/uojApi';
import 'remixicon/fonts/remixicon.css';



const FormCard = () => {
  const { setActiveState, getActiveState } = useProgressContext();


  const [formData, setFormData] = useState({
    name: '',
    voucherCode: '',
    identityNumber: '',
    documentType: 'Passport/NIC',

    payamount: null,
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [user, setUser] = useState({
    name: '',
    purpose: '',
    total: '',
  });

  const [isVoucherValid, setIsVoucherValid] = useState(true); //form validation
  const [authvoucher, setAuthVoucher] = useState(false); //after verified voucher state
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const active = getActiveState();
  const [aid, setAid] = useState(active.id);
  const [errors, setErrors] = useState({});
  

  useEffect(() => {
    setAid(active.id);
  }, [active]);

  const payamountRef = useRef();

  // post api call for 1 st stage
  const checkvoucher = async (vouchercode) => {
    try {
      const intvoucher = parseInt(vouchercode);
      const response = await uojApi.post(
        `/voucher-verify?voucher_code=${intvoucher}`
      );
      console.log('voucher response is', response);

      setAuthVoucher(true);
      setError(null);
      setSuccess(response.data.message);
      setUser({
        name: response.data.data.user_name,
        purpose: response.data.data.voucher,
        total: response.data.data.amount,
      });
      console.log('user is ', user);
    } catch (error) {
      setError(error.response.data.message);
      setSuccess(null);
      console.error('Error while verifying voucher', error);
      console.error('Error while verifying voucher', error);

      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
    } finally {
      console.log('verify finished');
    }
  };

  // final payment api call

  /*
  const paynowfunction = async (sta) => {
    try {
      const response = await uojApi.post(`/payment-status?status=${sta}`);
      console.log('paynow response is', response.data.message);
      setPaysuccess(response.data.message);
    } catch (error) {
      console.log(error);
      setPayfailed(error.response.data.message);
    }
  };
*/
  

  const handleChange = (e) => {
  const { name, value } = e.target;

  // Allow only positive integers
  if (name === "payamount" && value && !/^\d*$/.test(value)) {
    return; // Ignore invalid input
  }

  // Validate against the total amount
  if (name === "payamount" && parseFloat(value) > user.total) {
    setErrors({
      ...errors,
      payamount: `Pay amount cannot exceed Total Amount (${user.total}).`,
    });
    return; // Prevent setting invalid value
  }

  // Clear errors if input is valid
  if (name === "payamount") {
    setErrors({ ...errors, payamount: "" });
  }

  setFormData({ ...formData, [name]: value });
  setIsDropdownOpen(false);
};

  const handleVerify = () => {
    const isNumeric = /^[0-9]+$/.test(formData.voucherCode);
    if (formData.voucherCode.length === 16 && isNumeric) {
      setIsVoucherValid(true);
      checkvoucher(formData.voucherCode);
    } else {
      setIsVoucherValid(false);
    }
  };

  const handleClear = () => {
    setFormData({
      name: '',
      voucherCode: '',
      identityNumber: '',
      documentType: 'Passport/NIC',
    });
    setSuccess(null);
    setError(null);
    setIsVoucherValid(true);
    setAuthVoucher(false);
  };

  const handleamountClear = () => {
    setFormData({ ...formData, payamount: null });
    payamountRef.current.value = null;
    console.log('amount clear');
  };

  /*
  const paynow = () => {
    const activeState = getActiveState();
    if (activeState && activeState.id < 4) {
      setActiveState(activeState.id + 1);
    }
    console.log('final data ', formData);
    console.log('final user', user);

    paynowfunction('success');
  };
  */

  const handleNext2 = () => {
    if (!formData.payamount || !/^\d+$/.test(formData.payamount)) {
      setErrors({ ...errors, payamount: "Please enter you a Pay Amount." });
      return;
    }
  
    const activeState = getActiveState();
    if (activeState && activeState.id < 4) {
      setActiveState(activeState.id + 1);
    }
  
    console.log("Data from stage 2", formData);
  };
  

  const handleBack = () => {
    const activeState = getActiveState();
    if (activeState && activeState.id < 4) {
      setActiveState(activeState.id - 1);
    }
  };

const [payNowClicked, setPayNowClicked] = useState(false); // Track Pay Now button click

  // Function to fetch the payment form using uojApi
  useEffect(() => {
    const fetchPaymentForm = async () => {
      try {
        console.log("Sending payment data:", formData);

        // Sending POST request with payment data using uojApi
        const response = await uojApi.post(
          "/payment", // API endpoint for payment
          {
            amount: formData.payamount,
            identity_number: formData.identityNumber,
            identity_type: formData.documentType,
            payee_name: formData.name,
            voucher_code: formData.voucherCode,
          }
        );

        // Check if the response data is valid and contains a form
        if (response.data && response.data.data) {
          const formHTML = response.data.data.form; // Get the HTML form as a string
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = formHTML; // Set the HTML content inside a temporary div

          // Extract the form element from the HTML string
          const form = tempDiv.querySelector("form");
          if (form) {
            // Append form to the body and submit it
            document.body.appendChild(form);
            form.submit(); // Automatically submit the form
          } else {
            console.error("Form element not found in the response.");
          }
        }
      } catch (error) {
        // Handle errors and display an alert
        console.error("Error fetching payment form:", error);
        alert("Error fetching the payment form.");
      }
    };

    // Ensure fetchPaymentForm is called only when Pay Now is clicked and formData is valid
    if (payNowClicked) {
      if (
        formData.payamount &&
        formData.identityNumber &&
        formData.documentType &&
        formData.name &&
        formData.voucherCode
      ) {
        fetchPaymentForm();
      } else {
        alert("Please fill in all required fields.");
      }
      setPayNowClicked(false); // Reset the state to prevent repeated API calls
    }
  }, [payNowClicked, formData]); // Runs when payNowClicked or formData changes

  // Handle the "Pay Now" button click
  const handlePayNowClick = () => {
    setPayNowClicked(true); // Trigger the effect to fetch the payment form
  };

  const handleNext = () => {
    const handleValidation = () => {
      let isValid = true;
      let errorMsg = {};
  
      // Validate Voucher Code
      if (!formData.voucherCode || formData.voucherCode.length !== 16) {
        isValid = false;
        errorMsg.voucherCode = "Voucher code must be a 16-digit number.";
      }
  
      // Validate Name
      if (!formData.name.trim()) {
        isValid = false;
        errorMsg.name = "Name is required.";
      }
  
      // Validate Document Type
      if (!formData.documentType) {
        isValid = false;
        errorMsg.documentType = "Identity type is required.";
      }

  
  
      // Validate Identity Number
      if (formData.documentType === "NIC") {
        const nicRegexOld = /^[0-9]{9}[vVxX]$/; // e.g., 123456789V
        const nicRegexNew = /^[0-9]{12}$/; // e.g., 200123456789
        if (
          !formData.identityNumber.match(nicRegexOld) &&
          !formData.identityNumber.match(nicRegexNew)
        ) {
          isValid = false;
          errorMsg.identityNumber = "Invalid NIC format.";
        }
      } else if (formData.documentType === "Passport") {
        const passportRegex = /^[A-Z0-9]{7,10}$/; // e.g., AB1234567
        if (!formData.identityNumber.match(passportRegex)) {
          isValid = false;
          errorMsg.identityNumber = "Invalid Passport format.";
        }
      } else {
        isValid = false;
        errorMsg.identityNumber = "Identity number is required.";
      }
  
      setErrors(errorMsg);
      return isValid;
    };
  
    if (handleValidation()) {
      console.log("All fields are valid. Proceeding to next stage...");
      const activeState = getActiveState();
      if (activeState && activeState.id < 4) {
        setActiveState(activeState.id + 1);
      }
    } else {
      console.log("Validation failed. Please check the errors.");
    }
  };
  
  
  

  return (
    <div className='flex  items-center justify-center mt-10 font-Agbalumo'>
      <div className='bg-gray-400 text-sm lg:text-lg text-black rounded-md bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50  border-2 border-gray-100  shadow-lg p-8 lg:w-[50%]'>
        <h1 className='mb-8 text-xl  text-blue-700'>Pay Online</h1>

        {/* 1st stage */}
        {aid === 1 && (
          <>
            <div className='mb-8 '>
              <div className='mb-8 '>
                <div className='flex'>
                  <input
                    className='w-full  px-3 py-2 border rounded-l'
                    type='text'
                    id='voucherCode'
                    name='voucherCode'
                    placeholder='Voucher Code'
                    value={formData.voucherCode}
                    onChange={handleChange}
                    required
                  />
                  <button
                    className='bg-purple-500  text-white px-4 py-2 rounded-r'
                    onClick={handleVerify}
                  >
                    Verify
                  </button>
                </div>
                {!isVoucherValid && (
                  <p className='text-red-500'>
                    Voucher code must be a 16-digit number.
                  </p>
                )}
                {error && <p className='text-red-500'>{error}</p>}
                {success && <p className='text-green-700'>{success}</p>}
              </div>

              <div>
               <input
                className="w-full px-3 py-2 border rounded"
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
               />
              {errors.name && <p className="text-red-500">{errors.name}</p>}
              </div>

            </div>

            <div className="relative mb-8 lg:w-[25%] bg-white rounded">
             <div
              className="w-full px-3 py-2 border rounded cursor-pointer text-left"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
            {formData.documentType || "Select Document Type"}
            </div>
           {isDropdownOpen && (
            <div className="absolute top-12 left-0 right-0 bg-white border rounded shadow-md">
             <div
              className="p-2 cursor-pointer"
              onClick={() =>
              handleChange({
              target: { name: "documentType", value: "NIC" },
            })
            }
            >
            NIC
          </div>
          <div
            className="p-2 cursor-pointer"
            onClick={() =>
            handleChange({
            target: { name: "documentType", value: "Passport" },
            })
            }
           >
          Passport
          </div>
         </div>
          )}
          {errors.documentType && (
           <p className="text-red-500">{errors.documentType}</p>
           )}
         </div>

         <div className="flex-grow mb-8">
          <input
            className="w-full px-3 py-2 border rounded"
            type="text"
            id="identityNumber"
            name="identityNumber"
            placeholder="Identity Number"
            value={formData.identityNumber}
            onChange={handleChange}
            required
           />
          {errors.identityNumber && (
          <p className="text-red-500">{errors.identityNumber}</p>
           )}
         </div>
            <div className='flex justify-between'>
              <button
                className='bg-red-500 text-white px-4 py-2 rounded shadow-lg'
                onClick={handleClear}
              >
                Clear
              </button>
              <button
                className={`bg-blue-500 text-white px-4 py-2 rounded shadow-xl ${
                  !authvoucher ? 'bg-gray-200' : null
                }`}
                disabled={!authvoucher}
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </>
        )}
        {/* end of first stage */}

        {/* 2nd Stage */}
        {aid === 2 && (
          <>
            <div className='mb-8'>
              <div className='flex flex-col items-start shadow-lg px-5 p-2'>
                <h1 className='text-purple-600'>Student Details</h1>
                <h1 className='font-[Rajdhani] font-semibold text-left'>
                  User Name:{' '}
                  <span className='text-white ml-2'>{user.name}</span>
                </h1>
                <h1 className='font-[Rajdhani] font-semibold'>
                  Fee Type:{' '}
                  <span className='text-white ml-2'>{user.purpose}</span>
                </h1>
                <h1 className='font-[Rajdhani] font-semibold'>
                  Total Amount :{' '}
                  <span className='text-white ml-2'>{user.total}</span>
                </h1>
              </div>

              <div className='flex flex-col items-start mt-10 shadow-lg px-5 p-2'>
                <h1 className='text-purple-600'>Payer Details</h1>
                <span className='font-[Rajdhani] font-semibold'>
                  {' '}
                  Name:<span className='text-white ml-2'> {formData.name}</span>
                </span>
                <h1 className='font-[Rajdhani] font-semibold'>
                  {formData.documentType}:{' '}
                  <span className='text-white ml-2'>
                    {formData.identityNumber}
                  </span>
                </h1>
              </div>
              {/* <input
                className='w-full px-3 py-2 border rounded'
                type='text'
                id='name'
                name='name'
                placeholder='Name'
                value={formData.name}
                onChange={handleChange}
                required
              /> */}
            </div>
            {/* <div className='lg:flex gap-2'>
              <div className='relative mb-8 lg:w-[25%] bg-white rounded'>
                <div
                  className='w-full px-3 py-2 border rounded cursor-pointer text-left  '
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  {formData.documentType}
                </div>
                {isDropdownOpen && (
                  <div className='absolute top-12 left-0 right-0 bg-white border rounded shadow-md'>
                    <div
                      className='p-2 cursor-pointer'
                      onClick={() =>
                        handleChange({
                          target: {
                            name: 'documentType',
                            value: 'NIC/Passport',
                          },
                        })
                      }
                    >
                      NIC/Passport
                    </div>
                    <div
                      className='p-2 cursor-pointer'
                      onClick={() =>
                        handleChange({
                          target: { name: 'documentType', value: 'nic' },
                        })
                      }
                    >
                      NIC
                    </div>
                    <div
                      className='p-2 cursor-pointer'
                      onClick={() =>
                        handleChange({
                          target: { name: 'documentType', value: 'passport' },
                        })
                      }
                    >
                      Passport
                    </div>
                  </div>
                )}
              </div>
              <div className='flex-grow mb-8'>
                <input
                  className='w-full px-3 py-2 border rounded'
                  type='text'
                  id='identityNumber'
                  name='identityNumber'
                  placeholder='Identity Number'
                  value={formData.identityNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </div> */}

            {/* <div className='mb-8 flex'>
              <input
                className='w-full  px-3 py-2 border rounded-l'
                type='text'
                id='voucherCode'
                name='voucherCode'
                placeholder='Voucher Code'
                value={formData.voucherCode}
                onChange={handleChange}
                required
              />
              <button
                className='bg-purple-500  text-white px-4 py-2 rounded-r'
                onClick={handleVerify}
              >
                Verify
              </button>
            </div> */}



            <div className="mb-10">
             <input
              className="w-full px-3 py-2 border rounded"
              ref={payamountRef}
              type="text" // Keep it as text to allow better control of input
              id="payamount"
              name="payamount"
              placeholder="Pay Amount"
              value={formData.payamount}
              onChange={handleChange}
              required
            />
          {errors.payamount && <p className="text-red-500 mt-2">{errors.payamount}</p>}
            </div>





            <div className='flex justify-between'>
              <button
                className='bg-red-500 text-white px-4 py-2 rounded shadow-lg'
                onClick={handleamountClear}
              >
                Clear
              </button>

              <button
                className='bg-blue-500 text-white px-4 py-2 rounded shadow-xl'
                onClick={handleNext2}
              >
                Next
              </button>
            </div>
          </>
        )}

        {/* end of 2nd stage */}

        {/* 3rd Stage */}
        {aid === 3 && (
          <>
            <div className='mb-8'>
              <div className='flex flex-col items-start shadow-lg px-5 p-2'>
                <h1 className='text-purple-600'>Student Details</h1>
                <h1 className='font-[Rajdhani] font-semibold text-left'>
                  User Name:{' '}
                  <span className='text-white ml-2 '>{user.name}</span>
                </h1>
                <h1 className='font-[Rajdhani] font-semibold'>
                  Fee Type:{' '}
                  <span className='text-white ml-2'>{user.purpose}</span>
                </h1>
                <h1 className='font-[Rajdhani] font-semibold'>
                  Total Amount:{' '}
                  <span className='text-white ml-2'>{user.total}</span>
                </h1>
                <h1 className='font-[Rajdhani] font-semibold text-left '>
                  Voucher Code :
                  <span className='text-white ml-2'>
                    {formData.voucherCode}
                  </span>
                </h1>
              </div>

              <div className='flex flex-col items-start mt-10 shadow-lg px-5 p-2'>
                <h1 className='text-purple-600 mb-2'>payer Details</h1>
                <h1 className='font-[Rajdhani] font-semibold'>
                  {' '}
                  Name: <span className='text-white ml-2'>{formData.name}</span>
                </h1>
                <h1 className='font-[Rajdhani] font-semibold'>
                  <span>{formData.documentType}:</span>{' '}
                  <span className='text-white ml-2'>
                    {' '}
                    {formData.identityNumber}
                  </span>
                </h1>
                <h1 className='font-[Rajdhani] font-semibold'>
                  Paying Amount :
                  <span className='text-white ml-2'>
                    {parseFloat(formData.payamount).toFixed(2)}
                    </span>
                </h1>
              </div>
            </div>

            <div className='flex justify-between'>
              <button
                className='bg-red-500 text-white px-4 py-2 rounded shadow-lg'
                onClick={handleBack}
              >
                BACK
              </button>
              <button
                className='bg-blue-500 text-white px-4 py-2 rounded shadow-xl'
                onClick={handlePayNowClick}
              >
                PAY NOW
              </button>
            </div>
          </>
        )}
        {/* end of 3rd stage */}

        {/* 4rd Stage */}
        {aid === 4 && (
          <>
        
          <h1>Redirecting to Payment Gateway...</h1>
        
        </>
        )}
        {/* end of 4rd stage */}

      
    

      </div>
    </div>
  );
};

export default FormCard;
